
/* ============================================ backend ========================================= */
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap');
::selection {
    color: white;
    background: #002173;
  }
body{
    background-color: #f4f6fc!important;
    color: #2E384D!important;
    font-family: 'Rubik', sans-serif!important;
}

.text-muted {
    color: #8798AD !important;
}
span,p,a, div {
    font-family: 'Rubik', sans-serif!important;
}
@media (max-width: 991.98px){
    .header-mobile-fixed .header-mobile{
        z-index: 1023!important;

    }
    .brand-dark .header-mobile {
        background-color: #002173!important;
    }
    .brand-dark .header-mobile .burger-icon span {
        background-color: #fff!important;
    }
    .brand-dark .header-mobile .burger-icon span::before, .brand-dark .header-mobile .burger-icon span::after {
        background-color: #fff!important;
    }
    .brand-dark .header-mobile .btn .svg-icon svg g [fill] {
        fill: #fff!important;
    }
}
.aside-fixed .aside{
    z-index: 99999!important;
}
.aside {
    background-color: #002173!important;
    transition: all .3s linear;
}
.brand-dark .brand {
    background-color: #002173!important;
}
.aside-menu {
    background-color: #002173!important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading, .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link {
    background-color: #001548!important;
}
.aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-heading, .aside-menu .menu-nav > .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover > .menu-link {
    background-color: #001548!important;
}
.aside-menu .menu-nav > .menu-item > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #ededed!important;
}
.aside-menu .menu-nav > .menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #3699FF!important;
}
.aside-menu .menu-nav > .labTab.menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .labTab.menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #00c897!important;
}
.aside-menu .menu-nav > .labTab.fancy.menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .labTab.fancy.menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill]  {
    fill: rgb(240, 179, 63)!important;
}
.aside-menu .menu-nav > .natTab.fancy.menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .natTab.fancy.menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: rgb(187, 49, 76)!important;
}
.natTab.fancy .menu-link:hover .menu-icon.svg-icon svg g [fill] {
    fill: rgb(187, 49, 76)!important;
}
.natTab .menu-link:hover .menu-icon.svg-icon.natural-icon svg g [fill] {
    fill: #3699FF!important;
}
.labTab .menu-link:hover .menu-icon.svg-icon.lab-icon svg g [fill] {
    fill: #00c897!important;
}
.labTab.fancy .menu-link:hover .menu-icon.svg-icon svg g [fill] {
    fill: rgb(240, 179, 63)!important;
}
.menu-link:hover .menu-icon.svg-icon svg g [fill] {
    fill: #3699FF!important;
}
/* .natTab .menu-link:hover .menu-icon.svg-icon svg g [fill]  {
    fill: #3699FF
}
.aside-menu .menu-nav > .labTab.menu-item > .menu-link:hover .menu-icon.svg-icon svg g [fill]  {
    fill: #00c897
} */
.aside-menu .menu-nav > .natTab.menu-item.menu-item-active > .menu-heading .menu-icon.svg-icon svg g [fill], .aside-menu .menu-nav > .natTab.menu-item.menu-item-active > .menu-link .menu-icon.svg-icon svg g [fill] {
    fill: #3699FF!important;
}
.aside-menu .menu-nav > .menu-section {
    margin: 0!important;
}
.brand-dark .brand .btn .svg-icon svg g [fill] {
    fill: #ededed!important;
}
.custom_datatable_head{
    font-family: 'Rubik', sans-serif;
    background: #f3f5f9
}
.table td{
    font-family: 'Rubik', sans-serif;
    font-size: 14px;
    color:#2e384d;
}

.table.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fff;
}
.table.table-striped tbody tr:nth-of-type(even) {
    background-color: #eff3f6;
}
.table.table-striped .invoiceExpand tbody tr{
    background-color: #fff!important;
}
.confirmExpand{
    box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
    -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
}
.confirmExpand .table tbody tr:hover {
    background-color: #fff!important;
}
.confirmExpand .table tbody tr {
    vertical-align: middle;
}
.confirmExpand .table thead tr:hover{
    background-color: #fff!important;
    color: #B0BAC9;
}
.confirmExpand .table thead tr, .confirmExpand .table thead tr th{
      color: #B0BAC9;
      font-weight: 500;
      vertical-align: middle;
}
.table.table-hover tbody tr:hover {
    background-color: #eff3f680;
}
.invoiceExpand {
    background-color: #eff3f6!important;
}
.table.table-hover .invoiceExpand thead tr:hover {
    background-color: #eff7ff;
}
.table.table-hover .invoiceExpand thead tr {
    background-color: #eff7ff;
}
.border-left-danger{
    border-left: 3px solid #F64E60 !important;
}
.border-left-primary{
    border-left: 3px solid;
}
.table th, .table td {
    padding: 0.55rem;
    vertical-align: middle;
}
.table th, .table td {
    padding: 0.55rem;
    vertical-align: middle;
}
/* .table-hover tbody tr:hover {
    color: #3F4254;
    background-color: #EEF2FF;
} */
.table td.total{
    font-weight: 500;
}

.selected_stones, .selected_stones span{
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #2e384d;
}
.selected_diamonds_status{
    font-family: 'Rubik', sans-serif;
    font-size: 11px;
    font-weight: normal;
    color: #8798ad;
}
.page_size{
    font-size: 15px;
    font-weight: 500;
    font-family: 'Rubik', sans-serif;
    color: #2e384d;
}
.custom_hr{
    color:#c3c3c3;
}
.table_page_length{
    font-family: 'Rubik', sans-serif;
    font-size: 15px;
    font-weight: 500!important;
    font-style: italic;
    color: #8798ad!important;
}

.aside-menu .menu-nav .menu-item > .menu-heading, .aside-menu .menu-nav .menu-item > .menu-link {
    transition: all .7s linear;
    white-space: nowrap;
}
.aside-menu .menu-nav > .menu-section .menu-text {
    color: #ffffff60!important;
}
.aside-menu .menu-nav > .menu-section .menu-icon {
    color: #ffffff60!important;
}
.brand-logo{
    transition: all 1s linear;
}
.small_title{
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: 1.3px;
    color: #b0bac9;
}
.medium_title{
    font-family: Rubik;
    font-size: 18px;
    color: #2e384d;
}
/* .dasboardSearch{
    height: 480px;
} */
.dasboardSearch .nav .nav-link {
    color: #0037bf;
    font-size: 14px;
    font-family: Rubik;
    border: none;
    border-bottom: 3px solid transparent;
}
.dasboardSearch .nav .show > .nav-link, .dasboardSearch .nav .nav-link:hover:not(.disabled), .dasboardSearch .nav .nav-link.active {
    color: #2e384d;
    font-family: Rubik;
    font-size: 14px;
    line-height: 1.14;
}
.dasboardSearch .nav-tabs .nav-link.active, .dasboardSearch .nav-tabs .nav-item.show .nav-link {
    border: none;
    border-bottom: 3px solid #002173;
}
.dasboardSearch .nav .show > .nav-link, .dasboardSearch .nav .nav-link:hover:not(.disabled), .dasboardSearch .nav .nav-link.active {
    border: none;
    padding: 0.5rem 1.5rem;
    border-bottom: 3px solid #002173;
}
.diamondBox{
    border: solid 0.5px #dfecff;
    border-radius: 8px;
    height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.diamondBox:hover{
    background-color: #dfecff20;
    box-shadow: 0 .125rem .25rem rgba(0,0,21,.075);
}
.diamondBox p{
    color: #2e384d;
    text-align: center;
    font-family: Rubik;
    font-size: 16px;
    font-weight: 500;
    margin-top: 7px;
    margin-bottom: 0px;
}
.wbg {
    background-color: #7a9cff40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.wbg .white_dia_icon {
    background: url(../images/wishlist_icon.png) 0px 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.fbg {
    background-color: #bb314c40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.fbg .fancy_dia_icon {
    background: url(../images/wishlist_icon.png) -30px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.pbg {
    background-color: #7ccdf240;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}

.pbg .parcel_dia_icon {
    background: url(../images/wishlist_icon.png) 30px 0px;
    padding-top: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.lbg {
    background-color: #00c89740;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.lbg .lab_dia_icon {
    background: url(../images/wishlist_icon.png) 60px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.lfbg {
    background-color: #f0b33f40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.lfbg .lab_fancy_dia_one {
    background: url(../images/wishlist_icon.png) 90px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.lpbg {
    background-color: #b3f27c40;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 10px;
}
.lpbg .lab_parcel_dia_one {
    background: url(../images/wishlist_icon.png) 30px 0px;
    padding-top: 0px;
    margin: 0px;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    display: inline-block;
}
.tracklistdata{
    margin-top: 10px;
    max-height: 208px;
    min-height: 208px;
    position: relative;
    /* overflow-y: auto;
    overflow-x: hidden; */
    padding: 0px 5px;
}
.W_diamond {
    border-radius: 50%;
    background-color: #002173;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.F_diamond {
    border-radius: 50%;
    background-color: #bb314c;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.L_diamond {
    border-radius: 50%;
    background-color: #00c897;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.LF_diamond {
    border-radius: 50%;
    background-color: #f0b33f;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
.P_diamond {
    border-radius: 50%;
    background-color: #7CCDF2;
    color: #fff;
    margin-top: 2px !important;
    height: 15px;
    width: 15px;
    line-height: 17px;
    font-size: 8px!important;
    text-align: center;
    display: inline-block;
    font-family: Rubik;
}
/* .recentlyViewedDash{
    min-height: 495px;
} */
.small-box .icon>img {
    width: 58px;
    height: auto;
    position: absolute;
    right: 30px;
    top: 30%;
    transition: all .3s linear;
}
.small-box {
    border-radius: 5px;
    position: relative;
    box-shadow: 3px 3px 15px 0 rgba(0, 0, 0, 0.1);
    height: 140px;
    display: flex;
    align-items: center;
}
.profileIncompleteDashboard .small-box{
    height: 153px!important;
}
.small-box>.inner {
    padding: 9px 20px;
}
.small-box .icon {
    color: rgba(0, 0, 0, .15);
    z-index: 0;
}
.small-box h3 {
    font-size: 2.1rem;
    font-weight: 700;
    margin: 0 0 10px 0;
    padding: 0;
    white-space: nowrap;
}
.small-box:hover {
    text-decoration: none;
}
.small-box:hover .icon>img {
    width: 63px;
}
.small-box h3, .small-box p {
    z-index: 5;
    color: #fff;
}
.recentlyViewedDash .customRecently{
    min-height: 470px;
    overflow-x: auto;
}
 .recentlyViewedDash .grid_box{
    max-width: 290px;
    min-width: 290px;
} 
.Instock_label{
    color: #1bc5bd!important;
    border-radius: 5px!important;
    background-color: #d4fffd;
    font-family: Rubik!important;
    font-size: 10px!important;
    padding: 1px 6px;
    display: inline-block;
    font-weight: 500!important;
}
.Outofstock_label{
    color: #F64E60!important;
    background-color: #FFE2E5!important;
    border-radius: 5px!important;
    font-family: Rubik;
    font-size: 10px!important;
    padding: 1px 6px;
    display: inline-block;
    font-weight: 500!important;
}
.flagIco{
    width: 20px!important;
    /* border-radius: 5px; */
}
.availIco{
    height: 16px!important;
    /* border-radius: 5px; */
}
/* .savedSearchTile{
   height: 412px;
} */

.salesExecutiveTile{
   height: 194px;
}
.tooltip-inner {
    text-transform: capitalize;
}
.search_btn_check{
    margin-bottom: 0px!important;
}
.search_btn_check > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.search_btn_check > .selectBox {
    cursor: pointer;
    padding: 8px;
    /* margin: 1.5px; */
    text-align: center;
    background-color: #00217310;
    border: 1px solid #00217315;
    overflow: hidden;
}

.search_btn_check.iframe_setup > .selectBox {
    margin: 1.5px;
    border-radius: 25px;
    background-color: #F3F5F9;
    border: 1px solid #00000000;
}

.search_btn_check.iframe_setup.cuts .finishBox.selectBox,.search_btn_check.iframe_setup.polishes .finishBox.selectBox,.search_btn_check.iframe_setup.symmetries .finishBox.selectBox,.search_btn_check.iframe_setup.milky .finishBox.selectBox {
    width: 100px;
}
.iframe_setup .selectBox:hover {
    border:1px solid #2E5BFF;
}
.iframe_setup.search_btn_check > input:checked ~ .selectBox {
    animation: gradient 200ms;
    background-color: #2E5BFF;
}
/* .iframe_setup.search_btn_check > input:checked ~ .selectBox:active {
    animation: gradient 200ms;
    background: #2E5BFF;
    color: #F3F5F9;
    box-shadow: none;
  } */
@keyframes gradient {
    0% {
      background: radial-gradient(circle at center, #2e5bff4b 0%, #fff 0%, #F3F5F9 100%);
    }
    25% {
      background: radial-gradient(circle at center, #2e5bff7c 24%, #fff 25%, #F3F5F9 100%);
    }
    50% {
      background: radial-gradient(circle at center,#2e5bffb2 49%, #fff 50%, #F3F5F9 100%);
    }
    75% {
      background: radial-gradient(circle at center, #2e5bffde 74%, #fff 75%, #F3F5F9 100%);
    }
    100% {
      color: #fff;
      background: radial-gradient(circle at center, #2E5BFF 99%, #fff 100%, #F3F5F9 100%);
    }
  }
.labTab .search_btn_check > .selectBox {
    background-color: #00c89710;
    border: 1px solid #00c89715;
}

.search_btn_check .selectBox span{
    font-size: 12px;
    font-weight: 500;
    text-transform: uppercase;
    display: inline-block;
    line-height:15px;
}

.searchDemo .search_btn_check > .selectBox svg{
    height: 28px;
    width: auto;
}

.searchDemo .search_btn_check .shapeBox.selectBox{
    width: 42px;
    height: 42px;
}

.searchDemo .search_btn_check > .selectBox {
    cursor: pointer;
    /* padding: 7px; */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 3px;
    text-align: center;
    border-radius: 5px;
    background-color: #0037bf10;
    border: 1px solid #0037bf15;
    overflow: hidden;
    transition: all .3s ease-in-out;
}

.searchDemo .search_btn_check > input:checked ~ .selectBox {
    background-color: #0037bf;
}
.searchDemo .selectBox:hover {
    border:1px solid #0037bf90;
    box-shadow: 0 8px 6px -6px #777;
    transition: all .3s ease-in-out;    
    transform: scale(1.07);
}
.searchDemo .search_btn_check .wColorBox.selectBox{
    width: 30px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchDemo .search_btn_check .wClarityBox.selectBox{
    width: 40px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchDemo .search_btn_check .wFluorBox.selectBox{
    width: 90px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.searchDemo .search_btn_check .wLabBox.selectBox{
    width: 84px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas  .search_btn_check .selectBox span{
    font-size: 11px;
}
.search_btn_check .shapeBox.selectBox{
    width: 85px;
    height: 70px;
}

.parcelSearch .search_btn_check .shapeBox.selectBox{
    width: 75px;
    height: 65px;
}
.search_btn_check .clarityBox.selectBox{
    width: 70px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .clarityBox.selectBox{
    width: 50px;
    height: 30px;
}
.search_btn_check .labBox.selectBox{
    width: 85px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .parcelBox.selectBox{
    width: 65px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .labBox.selectBox{
    width: 60px;
    height: 30px;
}
.search_btn_check .shortcutBox.selectBox{
    width: 230px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .shortcutBox.selectBox{
    width: 150px;
    height: 30px;
}
.search_btn_check .finishBox.selectBox{
    width: 55px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .finishBox.selectBox{
    width: 50px;
    height: 30px;
}
.search_btn_check .fancyBox.selectBox{
    width: 120px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.search_btn_check .fancyBox.selectBox.API{
    width: 40;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .fancyBox.selectBox{
    width: 104px;
    height: 30px;
}
.search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .flourBox.selectBox{
    width: 100px;
    height: 30px;
}
.modified-search-canvas .search_btn_check .flourBox.eye.selectBox{
    width: 98px;
    height: 30px;
}
.search_btn_check .treatBox.selectBox{
    width: 180px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas  .search_btn_check .treatBox.selectBox{
    width: 110px;
    height: 30px;
}
.search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.modified-search-canvas .search_btn_check .tingeBox.selectBox{
    width:100%;
    height: 30px;
}
.search_btn_check .shapeBox.selectBox span{
    font-size: 11px;
    padding-top: 5px;
    display: inline-block;
    width: 65px;
}
.parcelSearch .search_btn_check .shapeBox.selectBox span{
    width: 58px;
}

.search_btn_check > input:checked ~ .selectBox {
    background-color: #002173;
}
.selectBox:hover {
    border:1px solid #002173;
}

.labTab .search_btn_check > input:checked ~ .selectBox {
    background-color: #00c897;
}

.labTab .selectBox:hover {
    border:1px solid #00c897;
}

.labTab .text-primary {
    color:#00c897!important;
}

.natTab .text-primary {
    color:#0037bf!important;
}

.natTab .labTab .text-primary {
    color:#00c897!important;
}

.labTab .natTab .text-primary {
    color:#0037bf!important;
}
span.submenu_link{
    font-size: 0.925rem;
    cursor: pointer;
    color :#B0BAC9;
    font-weight: 500;
}
.submenu_link{
    font-size: 0.925rem;
    color :#B0BAC9;
}
.search_btn_check > input:checked ~ .selectBox g [fill] {
    transition: fill 0.3s ease;
    stroke: #fff;
}
.search_btn_check > input:checked ~ .selectBox .triangle g [fill],.search_btn_check > input:checked ~ .selectBox .round g [fill] {
    fill: #fff;
}
.search_btn_check > input:checked ~ .selectBox span {
    transition: fill 0.3s ease;
    color: #fff;
}
.parcelSearch .search_btn_check > .selectBox svg{
    height: 30px;
    width: auto;
}
.search_btn_check > .selectBox svg{
    height: 36px;
    width: auto;
}
.search_accordion .card .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #002173!important;
    color: #FFF;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
}
.modified-search-canvas .search_accordion .card .card-header{
    font-size: 12px;
    background-color: #afc0eb!important;
    color: #000;
}
.modified-search-canvas.labTab .search_accordion .card .card-header{
    background-color: #afeede!important;
}
.modified-search-canvas .accordion .card .card-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='15' height='15' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' fill='%23fff'/%3E%3C/svg%3E");
}
.ccmode_accordion .card-header{
    padding: 12px 26px 9px 20px!important;
    background-color: #dee3ff!important;
    color: #002173;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 0px!important;
    cursor: pointer;
    margin-top:10px;
    margin-bottom: 10px;
}

.ccmode_accordion .card-header:after {

    content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='20' height='20' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'%3e%3cpath d='M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z' fill='%23002173'/%3e%3c/svg%3e");
    right: 45px;
    position: absolute;
    color: #002173;
    font-family: 'Font Awesome 5 Free';
    color: #002173;
}

.labTab .search_accordion .card .card-header{
    background-color: #00c897!important;
}

.accordion .card .card-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 16 16'%3E%3Cpath d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z' fill='%23fff'/%3E%3C/svg%3E");
    right: 19px;
    position: absolute;
}
.search_accordion .card {
    border: none!important;
}
.color{
    display: none!important;
}
.color.show{
    display: flex!important;
    flex-wrap: wrap;  
}
.carat{
    display: none!important;
}
.carat.show{
    display: flex!important;
    flex-wrap: wrap;
}
.expandedDetails{
    height: 385px;
    min-width: 1700px;
    background-color: #ffffff!important;
}
.muiExpandBox{
    /* box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
    -webkit-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset;
    -moz-box-shadow: 0px 0px 8px 1px rgba(0,0,0,0.28) inset; */
     background-color: #eff3f6;
}

.table.table-hover tbody tr.muiExpandBox:hover {
    background-color: #eff3f6!important;
}
.custom_datatable{
    min-width: 1850px;
}
.custom_datatable .MuiTableCell-head{
    white-space: nowrap;
}
.img_thumb{
    text-align: center;
    list-style-type: none;
    height: 50px;
    width: 50px;
    border: 1px solid #00000010;
    border-radius: 5px;
    margin-bottom:5px;
    overflow: hidden;
    background-color: #bcbebd;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_thumb img{
    height: 48px;
    margin: 0 auto;
}
.img_box{
    margin-left:10px ;
    width: 370px;
    height: 328px;
    background-color: #EBEDF3;
    text-align: center;
}
.img_box img{
    height: 328px;
    margin: 0 auto;
    object-fit: contain;
}
@media (min-width: 576px){
    .product_detail .detail_img_box{
        max-width: 470px;
        height: 400px!important;
    }
    .product_detail .detail_img_box img{
        height: 400px!important;
    }
}
.product_detail .detail_img_box{
    margin-left:10px ;
    width: 470px;
    height: auto;
    background-color: #EBEDF3;
    text-align: center;
}
.product_detail .detail_img_box img{
    height: auto;
    margin: 0 auto;
    object-fit: contain;
}
.search_result_dropdown .dropdown-toggle.nav-link:after, .dropdown-toggle.btn:after{
    content: ""!important;
}
.card-footer.sticky-top{
    bottom: 0;
    z-index: 1;
}
.checkbox.font-size-h6{
    font-size: 14px!important;
    font-weight: 500;
}
.form-control{
    border-radius: 0px!important;
}
.input-group-text{
    border-radius: 0px!important;
}
body::-webkit-scrollbar {
    height: 6px;
    width:  6px;
}
/*Track */
body::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}
/*Handle */
body::-webkit-scrollbar-thumb {
    background: #00217342;
    border-radius: 6px;
    box-shadow: inset 0 0 5px 3px #002173;
}
body::-webkit-scrollbar-thumb:hover {
    background: #0021738f;
    scrollbar-color: #0021738f rgb(0 33 115 / 56%);
}

.custom-scrollbar{
    overflow: auto;
}
.custom-scrollbar::-webkit-scrollbar {
    height: 6px;
    width:  6px;
}
/*Track */
.custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}
/*Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
    background: #00217342;
    border-radius: 6px;
    box-shadow: inset 0 0 5px 3px #002173;
}
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #0021738f;
    scrollbar-color: #0021738f rgb(0 33 115 / 56%);
}


/*Track */
.labTab .custom-scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
}
/*Handle */
.labTab .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #00c89742;
    border-radius: 10px;
    box-shadow: inset 0 0 5px 3px #00c897;
}
.labTab .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #00c8978f;
    scrollbar-color: #00c8978f rgb(0 33 115 / 56%);
}


.natTab .searchTableHeader tr{
    background-color: #002173;
}
.labTab .searchTableHeader tr{
    background-color: #00c897;
}
.searchTableHeader tr th{
    color:#fff
}
.gridData.card:hover {
    border-radius: 8px;
    box-shadow: 0 8px 8px -10px rgba(0,0,0,.68);
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    transition: all .3s ease-in-out;
    z-index: 0;
}
.gridData.card .img-fluid {
    height: 220px;
}

.price_border_right {
    position: absolute;
    border-right: 1px solid #bdbcbc;
    height: 85px;
    margin-top: 5px;
    right: 0;
}

.natTab .btn.btn-primary {
    background-color: #0037bf;
    border-color: #0037bf;
}

.natTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-primary:focus:not(.btn-text),  .natTab .btn.btn-primary.focus:not(.btn-text) {
    background-color: #0037bfcc;
    border-color: #0037bfcc;
}

.natTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-primary.dropdown-toggle, .natTab .show .btn.btn-primary.btn-dropdown {
    background-color: #0037bfcc;
    border-color: #0037bfcc;
}

.labTab .btn.btn-primary {
    background-color: #00c897;
    border-color:#00c897;
}

.labTab .btn.btn-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-primary:focus:not(.btn-text),  .labTab .btn.btn-primary.focus:not(.btn-text) {
    background-color: #00c897cc;
    border-color: #00c897cc;
}

.labTab .btn.btn-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-primary.dropdown-toggle, .labTab .show .btn.btn-primary.btn-dropdown {
    background-color: #00c897cc;
    border-color: #00c897cc;
}

.natTab .btn.btn-light-primary {
    background-color: #bed1ff;
    border-color: #0037bf00;
    color: #0037bf;
}
  
.natTab .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .natTab .btn.btn-light-primary:focus:not(.btn-text),  .natTab .btn.btn-light-primary.focus:not(.btn-text) {
    background-color: #0037bf;
    border-color: #0037bf;
}

.natTab .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .natTab .btn.btn-light-primary:not(:disabled):not(.disabled).active,  .natTab .show > .btn.btn-light-primary.dropdown-toggle, .natTab .show .btn.btn-light-primary.btn-dropdown {
    background-color: #0037bf;
    border-color: #0037bf;
}

.labTab .btn.btn-light-primary {
    color: #00c897;
    background-color: #00c8972b;
    border-color:#00c89700;
}

.labTab .btn.btn-light-primary:hover:not(.btn-text):not(:disabled):not(.disabled),  .labTab .btn.btn-light-primary:focus:not(.btn-text),  .labTab .btn.btn-light-primary.focus:not(.btn-text) {
    background-color: #00c897;
    border-color: #00c897;
}

.labTab .btn.btn-light-primary:not(:disabled):not(.disabled):active:not(.btn-text), .labTab .btn.btn-light-primary:not(:disabled):not(.disabled).active,  .labTab .show > .btn.btn-light-primary.dropdown-toggle, .labTab .show .btn.btn-light-primary.btn-dropdown {
    color:#fff!important;
    background-color: #00c897cc;
    border-color: #00c897cc;
}
.natTab .btn.btn-light-primary .cls-1 {
    fill: #0037bf;
}
.natTab .btn.btn-light-primary .cls-2 {
    fill: #fff;
}
.natTab .btn.btn-light-primary:hover .cls-1 {
    fill: #fff;
}
.natTab .btn.btn-light-primary:hover .cls-2 {
    fill: #0037bf;
}

.labTab .btn.btn-light-primary .cls-1 {
    fill: #00c897;
}
.labTab .btn.btn-light-primary .cls-2 {
    fill: #fff;
}
.labTab .btn.btn-light-primary:hover .cls-1 {
    fill: #00c897;
}
.labTab .btn.btn-light-primary:hover .cls-2 {
   fill: #fff;
}

.labTab .MuiTab-textColorPrimary.Mui-selected {
    color: #00c897;
    background-color: #00c89710;
}
.labTab .MuiTabs-indicator {
    background-color: #00c897;
}

.natTab .MuiTab-textColorPrimary.Mui-selected {
    color: #0037bf;
    background-color: #0037bf10;
}
.natTab .MuiTabs-indicator {
    background-color: #0037bf;
}
.MuiTab-wrapper {
    font-size: 15px;
    text-transform: capitalize;
}
hr{
    border-top: 1px solid #D5DEFF;
}

.grid_box{
    width: 295px;
}
.grid_box .grid_title{
    height: 45px;
}
@media (min-width: 726px){
    .grid_box .grid_title{
        height: 48px;
    }
}

.mtdr .font-size-sm{
    font-size: 0.87rem;
}
.min-card-header-height{
    min-height: 49px!important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: #fff!important;
}

.MuiSvgIcon-root.MuiTableSortLabel-icon{
    color: #ffffff54!important;
}
.MuiTableSortLabel-root:hover {
    color: #CCDBFF!important;
}

.custom_datatable th .MuiIconButton-root{
    padding: 0px 9px;
    color: #fff!important;
}

.wfp--dropzone{
    text-align: center;
    background-color: #F1F4FF;
    border: 2px #2E5BFF dashed;
    padding: 15px 10px;
    font-size: 16px;
    border-radius: 4px;
    font-weight: 600;
}
.CanNotHold{
    position: absolute;
    z-index: 9;
    font-weight: 500;
    color: red;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.CanNotHoldDiv{
    opacity: 0.3;
    pointer-events:none;
}
.cursor-na{
    cursor: not-allowed!important;
    pointer-events:none!important;
}
.profileLogo{
    border: 1px solid #cec9c95c;
    box-shadow: 2px 3px 5px -3px rgb(0 0 0 / 65%);
}

.custom_nav.nav.nav-pills .show > .nav-link, .custom_nav.nav.nav-pills .nav-link.active{
    background-color: #EEF2FF!important;
    color: #000!important;
    margin-left: -3px;
    font-weight: 500;
    border-radius: 0;
    border-left: 3px solid #2E5BFF;
}

.custom_nav.nav .show > .nav-link, .custom_nav.nav .nav-link:hover:not(.disabled), .custom_nav.nav .nav-link.active {
    background-color: #EEF2FF!important;
    color: #3F4254!important;
    margin-left: -3px;
    font-weight: 500;
    border-radius: 0;
    border-left: 3px solid #2E5BFF;
}
.custom_nav.nav.nav-pills .nav-link{
    margin-left: 3px;
    color: #3F4254!important;
}

ul.profile-scrollspy{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    color: #000!important;
    font-size: 14px;
    flex-direction: column!important;
}
ul.profile-scrollspy li{
    margin-left: 3px;
    color: #3F4254!important;
    margin-right: 0.25rem;
}
ul.profile-scrollspy a{
    color: #000!important;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    padding: 0.75rem 1.5rem;
    position: relative;
}
ul.profile-scrollspy .is-current{
    background-color: #EEF2FF!important;
    color: #000!important;
    font-weight: 500;
    border-radius: 0;
    border-left: 3px solid #2E5BFF;
    margin-left: -3px;
    transition: color .15s ease,background-color .15s ease,border-color .15s ease,box-shadow .15s ease;
}
ul.profile-scrollspy li:hover{
    background-color: #EEF2FF!important;
    color: #000!important;
    font-weight: 500;
    margin-left: -3px;
    border-radius: 0;
    border-left: 3px solid #2E5BFF;
    
}
.fancyExpand{
    height: 445px!important;
}

.gridCheck{
    position: absolute;
    top: 1px;
    left: 5px;
    z-index: 1;
}
.gridClose{
    position: absolute;
    top: 9px;
    right: 9px;
    z-index: 1;
}

.modal .modal-header .close span {
    display: block!important;
}
.setting-title{
    color:#8798AD;
    font-size: 16px;
    letter-spacing: 1px;
    font-weight: 500;
}

.alert{
    font-weight: 500!important;
}
.alert.alert-danger {
    color: #F64E63!important;
    background-color: #FEEDEF!important;
    border-color: #FEEDEF!important;
    font-weight: 500;
}
.alert.alert-success {
    color: #1BC5BD!important;
    background-color: #E8F9F8!important;
    border-color: #E8F9F8!important;
}
.alert.alert-warning {
    color: #F6AD4E!important;
    background-color: #FEF7ED!important;
    border-color: #FEF7ED!important;
}
.alert.alert-primary {
    color: #099CFF!important;
    background-color: #E6F5FF!important;
    border-color: #E6F5FF!important;
}
.diamJourny .MuiStepIcon-root.MuiStepIcon-completed{
    color:#0E9C3E!important;
    box-shadow:none!important   
}
.diamJourny .MuiStepIcon-root.MuiStepIcon-active{
    color:#fff!important;
    border-radius: 50%;
    box-shadow: 0 0px 2px 1px rgb(0 0 0 / 28%) !important;
}
.diamJourny .MuiStepIcon-root{
    color:#fff!important;
    border-radius: 50%;
    box-shadow: 0 0px 2px 1px rgb(0 0 0 / 28%) !important;
}
.recentlyViewedDash .grid_box button.close{
    position: absolute;
    top: 10px;
    right: 8px;
    z-index: 1;
}
.tpricing .card{
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16)!important;
    border-radius: 0!important;
 }

.tpricing .card .card-body{
    padding: 1.25rem 2.5rem;
}

.tpricing_heading{
    font-size: 28px;
    color:#002173;
    font-weight: 800;
    margin-top:30px;
    text-align: center;
 }
.price_benefits{
    border-radius: 15px;
    /* box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1); */
    background-color: #f5f6fb;
    padding: 25px 20px 30px 20px;
    margin:15px 0px 20px 0px;
    transition: all .2s ease-in-out;
 }
.price_benefits:hover{
    box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
    transform: scale(1.01);
 }
.price_benefits_title{
    font-size: 16px;
    font-weight: 600;
    color:#fff;
    background-color: #002173;
    padding: 5px 30px;
    border-radius: 25px;
    width:75%;
    text-align: center;
    margin:0 auto;
 }
 .price_benedits_tile{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 5px;
 }
 .price_benedits_tile span{
    font-size: 15px;
    font-weight: 500;
    color: #000;
    line-height: normal;
    width: 195px;
 }
 .price_benedits_tile .fa-check-circle{
    font-size: 16px;
    color:#002173!important;
 }
 .price_tile{
    margin: 15px 0px 20px 0px;
    padding: 0px;
 }
 .price_tile_title{
    background-color: #f5f6fb;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    padding: 5px 40px;
    display: none;
 }
 .price_tile_title span{
    font-size:15px;
    line-height: normal;
    font-weight: bold;
    color:#002173;
}
.price_tile_card{
    border-radius: 15px;
    border: solid 2px #002173!important;
    padding: 25px;
    display: flex;
    flex-wrap: wrap;
    color:#002173!important;
    justify-content: flex-start;
    line-height: 2;
    transition: all .2s ease-in-out;
}
.price_tile_card i{
    color: #002173;
}
@media (min-width: 576px){
    .price_tile_card{
        justify-content: space-between;
    }
    .price_tile_title{
        display: flex;
    }
    .price_tile {
        padding: 0px 30px;
    }
}
.price_tile_card .tprice_title{
    font-size: 18px;
    font-weight: 600;
    margin-left:10px;
}
.price_tile_card .tcharges{
    font-size: 25px;
    font-weight: 600;
}
.price_tile_card .charges_sub_title{
    font-size: 16px;
    margin-left:2px;
}
.price_tile_card:hover{
    background-color: #002173;
    color: #fff!important;
    transform: scale(1.03);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.price_tile_card:hover i{
    color: #fff!important;
}
.tpricing_con_heading{
    color:#002173;
    margin-top:20px;
    margin-bottom: 10px;
    font-size:16px;
    font-weight: 600;
    text-align: center;
}
.tpricing_con_descr{
    color:#002173;
    padding:15px;
    text-align: center;
    font-size:15px;
    border-radius: 15px;
    border: 2px solid #002173;
}

/* CSS ICON ALERT GENERAL */
.screenAlert-icon{border-radius:50%;border:4px solid gray;box-sizing:content-box;height:80px;margin:20px auto;padding:0;position:relative;width:80px}
.screenAlert-icon + .screenAlert-icon {margin-top:50px}

/* CSS SUCCESS */
.screenAlert-icon.screenAlert-success{border-color:#1bc5bd33}
.screenAlert-icon.screenAlert-success:after,
.screenAlert-icon.screenAlert-success:before{background-color:transparent!important;content:'';height:120px;position:absolute;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:60px}
.screenAlert-icon.screenAlert-success:before{border-radius:120px 0 0 120px;left:-33px;top:-7px;-webkit-transform-origin:60px 60px;transform-origin:60px 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-success:after{border-radius:0 120px 120px 0;left:30px;top:-11px;-webkit-transform-origin:0 60px;transform-origin:0 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-success .screenAlert-placeholder{border-radius:50%;border:4px solid #1bc5bd33;box-sizing:content-box;height:80px;left:-4px;position:absolute;top:-4px;width:80px;z-index:2}
.screenAlert-icon.screenAlert-success .screenAlert-line{background-color:#1bc5bd;border-radius:2px;display:block;height:5px;position:absolute;z-index:2}
.screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-tip{left:14px;top:46px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:25px}
.screenAlert-icon.screenAlert-success .screenAlert-line.screenAlert-long{right:8px;top:38px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);width:47px}
.animateSuccessTip{-webkit-animation:animateSuccessTip .75s;animation:animateSuccessTip .75s}
.animateSuccessLong{-webkit-animation:animateSuccessLong .75s;animation:animateSuccessLong .75s}
/* ANIMATE SUCCESS */
@-webkit-keyframes animateSuccessTip{0%,54%{width:0;left:1px;top:19px}70%{width:50px;left:-8px;top:37px}84%{width:17px;left:21px;top:48px}100%{width:25px;left:14px;top:45px}}
@keyframes animateSuccessTip{0%,54%{width:0;left:1px;top:19px}70%{width:50px;left:-8px;top:37px}84%{width:17px;left:21px;top:48px}100%{width:25px;left:14px;top:45px}}
@-webkit-keyframes animateSuccessLong{0%,65%{width:0;right:46px;top:54px}84%{width:55px;right:0;top:35px}100%{width:47px;right:8px;top:38px}}
@keyframes animateSuccessLong{0%,65%{width:0;right:46px;top:54px}84%{width:55px;right:0;top:35px}100%{width:47px;right:8px;top:38px}}


/* CSS ALERT */
.screenAlert-icon.screenAlert-error{border-color:#F27474}
.screenAlert-icon.screenAlert-error:after,
.screenAlert-icon.screenAlert-error:before{background-color:transparent!important;content:'';height:120px;position:absolute;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:60px}
.screenAlert-icon.screenAlert-error:before{border-radius:120px 0 0 120px;left:-33px;top:-7px;-webkit-transform-origin:60px 60px;transform-origin:60px 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-error:after{border-radius:0 120px 120px 0;left:30px;top:-11px;-webkit-transform-origin:0 60px;transform-origin:0 60px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.screenAlert-icon.screenAlert-error .screenAlert-placeholder{border-radius:50%;border:4px solid rgba(165,220,134,0.2);box-sizing:content-box;height:80px;left:-4px;position:absolute;top:-4px;width:80px;z-index:2}
.screenAlert-icon.screenAlert-error .screenAlert-line{background-color:#A5DC86;border-radius:2px;display:block;height:5px;position:absolute;z-index:2}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-tip{left:14px;top:46px;-webkit-transform:rotate(45deg);transform:rotate(45deg);width:25px}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-long{right:8px;top:38px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg);width:47px}
.screenAlert-icon.screenAlert-error .screenAlert-x-mark{display:block;position:relative;z-index:2}
.screenAlert-icon.screenAlert-error .screenAlert-placeholder{border:4px solid rgba(200,0,0,0.2)}
.screenAlert-icon.screenAlert-error .screenAlert-line{background-color:#F27474;top:37px;width:47px}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-left{left:17px;-webkit-transform:rotate(45deg);transform:rotate(45deg)}
.screenAlert-icon.screenAlert-error .screenAlert-line.screenAlert-right{right:16px;-webkit-transform:rotate(-45deg);transform:rotate(-45deg)}
.animateXLeft{-webkit-animation:animateXLeft .75s;animation:animateXLeft .75s}
.animateXRight{-webkit-animation:animateXRight .75s;animation:animateXRight .75s}
/* ANIMATE CROSS X */
@-webkit-keyframes animateXLeft{0%,65%{left:82px;top:95px;width:0}84%{left:14px;top:33px;width:47px}100%{left:17px;top:37px;width:47px}}
@keyframes animateXLeft{0%,65%{left:82px;top:95px;width:0}84%{left:14px;top:33px;width:47px}100%{left:17px;top:37px;width:47px}}
@-webkit-keyframes animateXRight{0%,65%{right:82px;top:95px;width:0}84%{right:14px;top:33px;width:47px}100%{right:16px;top:37px;width:47px}}
@keyframes animateXRight{0%,65%{right:82px;top:95px;width:0}84%{right:14px;top:33px;width:47px}100%{right:16px;top:37px;width:47px}}

/* CSS WARNING */
.screenAlert-icon.screenAlert-warning{border-color:#F8BB86}
.screenAlert-icon.screenAlert-warning:before{-webkit-animation:pulseWarning 2s linear infinite;animation:pulseWarning 2s linear infinite;background-color:transparent!important;border-radius:50%;content:"";display:inline-block;height:100%;opacity:0;position:absolute;width:100%}
.screenAlert-icon.screenAlert-warning:after{background-color:transparent!important;border-radius:50%;content:'';display:block;height:100%;position:absolute;width:100%;z-index:1}
.screenAlert-icon.screenAlert-warning .screenAlert-body{background-color:#F8BB86;border-radius:2px;height:47px;left:50%;margin-left:-2px;position:absolute;top:10px;width:5px;z-index:2}
.screenAlert-icon.screenAlert-warning .screenAlert-dot{background-color:#F8BB86;border-radius:50%;bottom:10px;height:7px;left:50%;margin-left:-3px;position:absolute;width:7px;z-index:2}
.scaleWarning{-webkit-animation:scaleWarning .75s infinite alternate;animation:scaleWarning .75s infinite alternate}
.pulseWarningIns{-webkit-animation:pulseWarningIns .75s infinite alternate;animation:pulseWarningIns .75s infinite alternate}
/* ANIMATE WARNING */
@-webkit-keyframes scaleWarning{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@keyframes scaleWarning{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@-webkit-keyframes pulseWarning{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#F8BB86;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@keyframes pulseWarning{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#F8BB86;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@-webkit-keyframes pulseWarningIns{0%{background-color:#F8D486}100%{background-color:#F8BB86}}
@keyframes pulseWarningIns{0%{background-color:#F8D486}100%{background-color:#F8BB86}}

/* CSS INFO */
.screenAlert-icon.screenAlert-info{border-color:#9de0f6}
.screenAlert-icon.screenAlert-info:before{-webkit-animation:pulseInfo 2s linear infinite;animation:pulseInfo 2s linear infinite;background-color:transparent!important;border-radius:50%;content:"";display:inline-block;height:100%;opacity:0;position:absolute;width:100%}
.screenAlert-icon.screenAlert-info:after{background-color:transparent!important;border-radius:50%;content:'';display:block;height:100%;position:absolute;width:100%;z-index:1}
.screenAlert-icon.screenAlert-info .screenAlert-body{background-color:#3fc3ee;border-radius:2px;height:47px;left:50%;margin-left:-2px;position:absolute;top:10px;width:5px;z-index:2}
.screenAlert-icon.screenAlert-info .screenAlert-dot{background-color:#3fc3ee;border-radius:50%;bottom:10px;height:7px;left:50%;margin-left:-3px;position:absolute;width:7px;z-index:2}
.scaleInfo{-webkit-animation:scaleInfo .75s infinite alternate;animation:scaleInfo .75s infinite alternate}
.pulseInfoIns{-webkit-animation:pulseInfoIns .75s infinite alternate;animation:pulseInfoIns .75s infinite alternate}
/* ANIMATE INFO */
@-webkit-keyframes scaleInfo{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@keyframes scaleInfo{0%{-webkit-transform:scale(1);transform:scale(1)}30%{-webkit-transform:scale(1.02);transform:scale(1.02)}100%{-webkit-transform:scale(1);transform:scale(1)}}
@-webkit-keyframes pulseInfo{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#9de0f6;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@keyframes pulseInfo{0%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}30%{background-color:#fff;-webkit-transform:scale(1);transform:scale(1);opacity:.5}100%{background-color:#9de0f6;-webkit-transform:scale(2);transform:scale(2);opacity:0}}
@-webkit-keyframes pulseInfoIns{0%{background-color:#3fc3ee}100%{background-color:#9de0f6}}
@keyframes pulseInfoIns{0%{background-color:#3fc3ee}100%{background-color:#9de0f6}}


.muiUploader.muiuploader-border{
    border: 1px solid #b0b1b6;
    position: relative;
}
.muiuploader-error{
    position: absolute;
    top: 55px;
    left: 17px;
    font-size: 10px;
    color: #F64E63;
}
.uploaderErrorBorder{
    border-color: #F64E63!important;
}
::-webkit-calendar-picker-indicator {
    color: rgba(0, 0, 0, 0);
    opacity: 1;
    background-image: url('data:image/svg+xml;charset=utf8,%3Csvg fill="%23000" fill-opacity=".54" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M19 3h-1V1h-2v2H8V1H6v2H5c-1.11 0-1.99.9-1.99 2L3 19c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 16H5V8h14v11zM7 10h5v5H7z"/%3E%3Cpath d="M0 0h24v24H0z" fill="none"/%3E%3C/svg%3E');
    width: 14px;
    height: 18px;
    cursor: pointer;
    border-radius: 50%;
    margin-left: .5rem;
  }
  .react-tel-input .form-control{
    width: 100%;
    height: 54px;
    border-radius: 4px!important;
    font-size: 13px !important;
  }
  .react-tel-input .flag-dropdown {
    background-color: #ffffff;
    border-right: none;
    border-radius: 4px 0 0 4px;
}
.plugin-api-option-box{
    padding:20px;
    display: inline-block;
    border: 1px solid #cec9c95c;
    background: rgb(246, 248, 252);
    background: linear-gradient(357deg, rgba(246, 248, 252, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 1) 100%);
    box-shadow: 0px 0px 7px 0px rgb(0 0 0 / 10%);
    transition: all .3s ease-in-out;
    border-radius: 5px;
}
.plugin-api-option-box p{
    color: #313131;
    line-height: normal;
}
.plugin-api-option-box .custom-checkbox{
    line-height: normal;
}
.react-tel-input .invalid-number-message {
    position: absolute;
    z-index: 1;
    font-size: 10px;
    left: 15px;
    top: 56px;
    background: #fff;
    padding: 0 2px;
    color: #F64E63;
}
.react-tel-input .form-control.invalid-number {
    border: 1px solid #F64E63;
    background-color: #fff;
    border-left-color: #cacaca;
    color: #F64E63;
}
.react-tel-input .flag-dropdown.invalid-number {
    border-color: #F64E63;
}
.profileLogoBox{
    min-width: 130px;
    min-height: 130px;
}
.logoUpploadBtn{
    position: absolute;
    top: 92px;
    padding: 5px 8px;
    /* width: auto!important; */
    /* max-width: auto!important; */
    border-radius: 50%;
    right: 153px;
    cursor: pointer;
    background-color: #002173;
    color: #fff;
}
.profileShipping p {
    margin-bottom: 0;
}
.MuiSnackbar-root {
    z-index: 99999!important;
}
.MuiBackdrop-root{
    cursor: wait;
}
.white_dia_icon {
    background: url(../../../public/media/General/dashboard_icon.png) 0px 0px;
    width: 40px;
    height: 40px;
    display: inline-block;
    vertical-align: middle !important;
    
}
.fancy_dia_icon {
    background: url(../../../public/media/General/dashboard_icon.png);
    width: 40px;
    height: 40px;
    background-position-x: -40px;
    display: inline-block;
    vertical-align: middle !important;
}
.lab_dia_icon {
    background: url(../../../public/media/General/dashboard_icon.png);
    width: 40px;
    height: 40px;
    background-position-x: -120px;
    display: inline-block;
    vertical-align: middle !important;
}
.lab_fancy_dia_icon {
    background: url(../../../public/media/General/dashboard_icon.png);
    width: 40px;
    height: 40px;
    background-position-x: -282px;
    display: inline-block;
    vertical-align: middle !important;
}
.multitab_diam_box{
    display: inline-block;
    text-align: center;
    position: relative;
    cursor: pointer;
    border-radius: 5px;
    border: 1px solid #cec9c980;
    padding: 15px 25px;
    line-height: normal;
    width: 129px;
    height: 120px;
    background-color: #F9FAFF;
    font-weight: 500;
    flex-direction: column;
}
.multitab_diam_box.checkbox:hover{
    box-shadow: 2px 3px 5px -3px rgb(0 0 0 / 65%);
}
.multitab_diam.table_cell_checkmark1{
    display: inline-block;
    padding: 0;
    margin: 0;
    margin: 0 13px
}
.multitab_diam.table_cell_checkmark1 .checkmark1{
    top: 5px;
    left: unset;
    right: 7px;
    box-shadow: 0px 0px 2px 1px #adabab;
}
.multitab_diam.table_cell_checkmark1 input:checked~.checkmark1 {
    background-color: #002173;
}
/* .multitab_diam.table_cell_checkmark1 .checkmark1:after {
    background-color: #002173;
} */
.payment_method{
    position: relative;
    cursor: pointer;
    margin: 0px 10px
}
.payment_method input.payment_method_input{
    position: absolute;
    bottom: 3.5px;
    left: 44px;
}
.payment_method .payment_method_box {
    background-color: #F5F5F5;
    width: 200px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 13px;
    padding: 20px;
    /* border: 1px solid #00000050; */
}
.payment_method  input.payment_method_input:checked~.payment_method_box {
    background-color: #EEF2FF;
    border: 1px solid #2E5BFF;
}
.payment_method  input.payment_method_input:checked~span {
    color:#000;
}
.payment_method  span {
    color:#8798AD;
}
.checkmark1 {
    border-radius: 50%;
    position: absolute;
    top: -2px;
    left: 5px;
    height: 16px!important;
    width: 16px!important;
    box-shadow: 0px 0px 2px 0px #adabab;
    background-color: #ffffff;
}
.multitab_diam_box.active{
    background-color: #fff;
    border: solid 1px #002173;
    color: #313131;
}
.multitab_diam_box.active p{
    color: #313131;
}
/* .checkbox > span:after {
    content: none!important;
   
} */
.multitab_diam_box.checkbox > span {
    background-color: transparent;
    border: 1px solid transparent;
}
.customColorPicker{
    display: inline-flex;
    align-items: center;
    width: 150px;
    max-width: 150px;
    /* padding: 12px 12px; */
    border: 1px solid #00000047;
    border-radius: 0px;
}
.colorPickerColorInput {
    margin-right: 8px;
    -webkit-appearance: none;
    border: none;
    width: auto;
    height: auto;
    cursor: pointer;
    background: none;
    padding: 0;
}
.colorPickerColorInput::-webkit-color-swatch-wrapper {
    padding: 0;
    width: 45px;
    height: 40px;
}
.colorPickerColorInput::-webkit-color-swatch {
    border: 1px solid #00000020;
    border-radius: 0px;
    /* border: none; */
    padding: 0;
}
.colorPickerTextInput{
    border: none;
    width: 100%;
    font-size: 14px;
}
.colorPickerTextInput:focus{
    border: none;
   outline: none;
}
.api-home-svg{
    position: absolute;
    bottom: 0;
    right: 0;
 }
 @media (min-width:700px){
    .api-home-svg svg{
        width: 610px;
    }
}
@media (min-width:992px){
    .api-home-svg svg{
        width: 460px;
    }
}
@media (min-width:1100px){
    .api-home-svg svg{
        width: 440px;
    }
    #API-request-page .api-req-title{
        font-size: 20px;
    }
    #apitable .nav-tabs .nav-link{
        font-size: 16px ;
    }
    input.custom_myTable_filter{
        font-size: 12px;
        padding:10px 5px;
        height: 30px;
    }
    .feed-opt-box .col-xl-3{
        -ms-flex: 0 0 33%;
        flex: 0 0 33%;
        max-width: 33%;
    }
}
@media (min-width:1440px){
    .api-home-svg svg{
        width: 460px;
    }
    #API-request-page .api-req-title{
        font-size: 22px;
    }
    #apitable .nav-tabs .nav-link{
        font-size: 18px ;
    }
    input.custom_myTable_filter{
        font-size: 14px;
        padding:10px 15px;
        height: 33px;
    }
}
@media (min-width:1500px){
    .api-home-svg svg{
        width: 800px;
    }
    .feed-opt-box .col-xl-3{
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
    }
}
.dimondAPIFeedIcon {
    background-color: #002173;
    padding: 6px;
    border-radius: 50px;
    width: 50px;
    height: 50px;
}
.api-home-svg.api-success .st0{
    fill: #E8F9F8;
}

.api-home-svg.api-failed .st0{
    fill: #FEEDEF;
}
.plugin-api-option-box .custom-control-label::before{
    /* top: 0!important; */
    left: -1.45rem;
}
/* .plugin-api-option-box .custom-control-label::after{
    top: 0!important;
} */
.MuiInputBase-input.Mui-disabled {
    background-color: #F3F6F9;
    cursor: not-allowed;
}
.MuiStepIcon-root {
    color: #aab9cc9e;
}

.embIcons{
    display: flex;
    font-size: 15px;
    position: absolute;
    right: 90px;
    bottom: 22px;
}
.embIcons svg{
    cursor: pointer;
}
.embIcons svg:hover{
    scale: 1.1;
}
.apiIcon svg:hover{
    scale: 1.1;
}
.example.example-compact .example-toggle.example-toggled {
    background-color: #ffffff00;
}
.example .example-code .example-highlight {
    padding: 0.5rem!important;
}
.card.input-code .example-highlight > pre code {
    overflow: auto;
    max-height: 270px!important;
    scrollbar-color: #b0bac9 #b0bac950;
    scrollbar-width: thin;
}
.card.input-code .example-highlight > pre code::-webkit-scrollbar {
    width:7px;
    height: 7px;
}
/* Track */
.card.input-code .example-highlight > pre code::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #b0bac938;
}
/* Handle */
.card.input-code .example-highlight > pre code::-webkit-scrollbar-thumb {
    background: #b0bac9;
}
.card.input-code {
    /* padding: 6px 15px; */
    background-color: #150f0f;
    color: #b0bac9;
    font-size: 14px;
    border-radius: 5px 5px 0px 0px;
}
.card.input-code .card-label{
    color: #b0bac9!important;
}
.card.input-code .card-header{
    min-height: 48px!important;
}
.card.input-code .card-body{
    padding: 0!important;
}
.card.input-code.example.example-compact .example-code {
    background-color: #F3F6F900;
    border-radius: 0!important;
}
.card.input-code .example-highlight > pre code{
    color: #b0bac9!important;
}

/* ------------------------------------------------------------------------------ */
.card.response-code .example-highlight > pre code {
    overflow: auto;
    max-height: 270px!important;
    scrollbar-color: #b0bac9 #b0bac950;
    scrollbar-width: thin;
}
.card.response-code .example-highlight > pre code::-webkit-scrollbar {
    width:7px;
    height: 7px;
}
/* Track */
.card.response-code .example-highlight > pre code::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #b0bac938;
}
/* Handle */
.card.response-code .example-highlight > pre code::-webkit-scrollbar-thumb {
    background: #b0bac9;
}
.card.response-code .card-header{
    min-height: 48px!important;
    background-color: #c8cfda!important;
}
.card.response-code .card-body{
    padding: 0!important;
}
.card.response-code.example.example-compact .example-code {
    /* background-color: #F3F6F900; */
    border-radius: 0!important;
}

.response-code-header{
    padding: 6px 15px;
    background-color:  #c8cfda;
    color: #000000;
    font-size: 14px;
    border-radius: 5px 5px 0px 0px;
}
.code-input-data {
    min-height: 78px;
    max-height: 270px;
    background-color: #1a1414;
    color:  #b0bac9;
    border-radius: 0px 0px 5px 5px;
    word-wrap: normal;
    overflow-wrap: normal;
    text-align: justify;
}
pre.code-input-data , pre.code-response-data{
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    line-height: 1.5;
    tab-size: 4;
    hyphens: none;
}
code.language-script{
    background-color: #00000000;
    padding: 0.15rem 0rem;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    overflow-wrap: normal;
    tab-size: 4;
    hyphens: none;
    position: relative;
}
.code-input-data span{
    white-space: nowrap;
    /* display:block; */
}
.code-input-data span span{
    color: #33ac2e;
    white-space: nowrap;
}
.code-response-data {
    min-height: 78px;
    max-height: 270px;
    background-color: #c8cfda14;
    color: #ff952c;
    border-radius: 0px 0px 5px 5px;
    border: 1px solid #70707014;
    word-wrap: normal;
    overflow-wrap: normal;
    text-align: justify;
}
.code-response-data span{
    white-space: nowrap;
}
.code-response-data span span{
    color:#33ac2e;
    white-space: nowrap;
}
.custom-scroll {
    overflow: auto;
    scrollbar-color: #002173 #86848450;
    scrollbar-width: thin;
}
.custom-scroll::-webkit-scrollbar {
    width:7px;
    height: 7px;
}
/* Track */
.custom-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 15px #00217338;
}
/* Handle */
.custom-scroll::-webkit-scrollbar-thumb {
    background: #002173;
}
.header.header-fixed{
    z-index: 1022!important;
}
.offcanvas {
    z-index: 1023!important;
}
.callout-danger {
    /* border-left-color: #e55353; */
    border-left:3px solid #e55353;
}
.callout {
    position: relative;
    padding: 0 1rem;
    margin: 1rem 0;
    border-radius: 0.25rem;
}
.anchorspace {
    height: 70px;
    margin-top: -70px;
}
.MuiSkeleton-root {
    background-color: rgb(0 0 0 / 10%)!important;
}
.quick-search-dropdown .MuiPaper-root{
    width: 100%!important;
}
.no-bullets {
    list-style-type: none;
  }
.Mui-focused.MuiInputLabel-outlined, .MuiFormLabel-filled.MuiInputLabel-outlined {
    background-color: #fff;
    padding: 2px 5px!important;
}
.modified-search-canvas .MuiOutlinedInput-input {
    padding: 12.5px 14px!important;
}
.modified-search-canvas .MuiInputLabel-outlined {
    transform: translate(14px, 12px) scale(1);
}
.modified-search-canvas  .MuiOutlinedInput-root {
    border-radius: 0px;
}
.MuiOutlinedInput-notchedOutline {
    border-color:#E4E6EF!important;
}
.searchResult .selection, .searchResult .expander,.searchResult .Media ,.confirmResult .selection,.confirmResult .expander{
    display: none;
}
.creditcards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px
}
.master-card {
    background: url(../images/payment-method.png) -80px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.visa-card {
    background: url(../images/payment-method.png) 0px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.discover-card {
    background: url(../images/payment-method.png) -209px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.union-card {
    background: url(../images/payment-method.png) -250px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.aac-card {
    background: url(../images/payment-method.png) -124px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.dc-card {
    background: url(../images/payment-method.png) -290px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.jcb-card {
    background: url(../images/payment-method.png) -167px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.mestro-card {
    background: url(../images/payment-method.png) -38px 0px;
    width: 43px;
    height: 28px;
    display: inline-block;
    padding-top: 5px;
    vertical-align: middle !important;
}
.invAddress p {
    margin-bottom: 0px;
}

.multi_currency_dropdown .MuiSelect-select{
    padding: 0.50rem 1.25rem!important;
    border: 1px #F64E63 dotted;
    text-align: center;
    background-color: #fff;
}
.multi_currency_dropdown svg{
    display: none;
}
.multi_currency_dropdown .MuiInput-underline:before{
    border-bottom: none!important;
}
.multi_currency_dropdown .MuiInputBase-root{
    color: #F64E63 !important;
    font-weight: 500;
    background-color: #fff;
    text-align: center;
}
.multi_currency_dropdown .Mui-disabled{
    font-weight: 500;
    cursor: not-allowed;
    background-color: #f4f4f4;
}
.multi_currency_dropdown .MuiInput-underline:after{
    border-bottom: 2px solid #F64E63!important;
}
.multi_currency_dropdown .MuiInput-colorSecondary.MuiInput-underline:after {
    border-bottom-color: #F64E63;
  }
.compareDiam{
    width: auto!important;
    height: 126px!important;
}
.matchingPairTr{
    background-color: #e1f0ff;
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important;
    /* border-bottom: 1px solid #0037bf; */
}
.matchingPairTr td{
   color:#0037bf!important;
   font-size: 1.2rem!important;
   /* border-bottom: 1px solid #0037bf;
   border-top: 1px solid #0037bf!important; */
}
.matchingPairTr .m_expander,.matchingPairTr .m_check{
    display: none;
}
.compareEmptyBox div{
    box-shadow: 0 0 1px rgba(0,0,0,.125), 0 1px 3px rgba(0,0,0,.2)!important;
    background-color: #ebedf3;
    border: 1px solid #cdcdcd;
    /* border-radius: 8px; */
    display: flex;
    /* align-content: center; */
    align-items: center;
    justify-content: center;
    height: 390px;
    width: 300px;
    margin: 0 auto;
}
.compare-diamond-col-3{
    flex:0 0 25%;
    max-width: 50%;
}


.pricing-plan {
    margin: 0 0 1rem 0;
    width: 100%;
    position: relative;
    background: #ffffff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.pricing-plan .pricing-header {
    padding: 0;
    margin-bottom: 1rem;
    text-align: center;
    background: linear-gradient(120deg, #00b5fd 0%, #0047b1 100%);
    -webkit-border-radius: 4px 4px 0px 0px;
    -moz-border-radius: 4px 4px 0px 0px;
    border-radius: 4px 4px 0px 0px;
}
.pricing-plan .pricing-header .pricing-title {
    font-size: 1.2rem;
    color: #ffffff;
    padding: 1rem 0;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0;
    text-shadow: 0 30px 10px rgba(0, 0, 0, 0.15);
}
.pricing-plan .pricing-header .pricing-cost {
    color: #ffffff;
    padding: 1rem 0;
    font-size: 2.5rem;
    font-weight: 700;
    text-shadow: 0 30px 10px rgba(0, 0, 0, 0.15);
}
.pricing-plan .pricing-header .pricing-save {
    color: #ffffff;
    padding: 0.8rem 0;
    font-size: 1rem;
    font-weight: 700;
}
.pricing-plan .pricing-header.secondary {
    background-image: linear-gradient(120deg, #c0d64a 0%, #35690f 100%);
}
.pricing-plan .pricing-header.red {
    background-image: linear-gradient(120deg, #ff3434 0%, #a50000 100%);
}
.pricing-plan .pricing-features {
    padding: 0;
    margin: 20px 0;
    text-align: left;
}
.pricing-plan .pricing-features li {
    padding: 15px 15px 15px 40px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    line-height: 100%;
}

.pricing-plan .pricing-footer {
    -webkit-border-radius: 0 0 3px 3px;
    -moz-border-radius: 0 0 3px 3px;
    border-radius: 0 0 3px 3px;
    text-align: center;
    padding: 1rem 0 2rem 0;
}

@media (max-width: 767px) {
    .pricing-plan .pricing-header {
        text-align: center;
    }
    .pricing-plan .pricing-header i {
        display: block;
        float: none;
        margin-bottom: 1.5rem;
    }
}
.videosharemodal {width:48% !important; top:-10%;}
.largerCheckbox {
    width: 18px;
    height: 18px;
}
.parcelResult .parcelSummery{
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}
.parcelResultBlock{
    display: block;
    /* transform: scaleX(1);
    transition: transform 1s ease;
    transform-origin: right; */
}
/* .parcelResultBlock[parcelResultOpenAnim='1']{
    transform: scaleX(1);
    transform-origin: left;
    transition: transform 1.5s ease;
} */

.shadow-bottom{box-shadow:0px 6px 6px -6px #0000004a;}